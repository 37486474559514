@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic");

@import '~@fortawesome/fontawesome-free/css/all.css';

@import '~overlayscrollbars/css/OverlayScrollbars.css';

@import '~icheck-bootstrap/icheck-bootstrap.css';

@import '../dist/css/adminlte.css';

.onoffswitch {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 36px;
  padding: 0;
  line-height: 36px;
  border: 2px solid #E3E3E3;
  border-radius: 36px;
  background-color: #D90B0B;
  transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 36px;
  margin: 0px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 22px;
  border: 2px solid #E3E3E3;
  border-radius: 36px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #49E845;
}

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #49E845;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
}

